import * as React from 'react';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import authAxios from '../utils';
import { updateDriverStatus } from '../constants';

const DriverDialog = ({callback, row}) => {
    const [openInputDialog, setOpenInputDialog] = React.useState(false);
    const [rejectReason, setRejectReason] = React.useState("");
    const handleClose = () => {
      callback();
    };

    const rejectDriver = () => {
        setOpenInputDialog(false);
        authAxios.post(updateDriverStatus, {id: row.id, reason: rejectReason})
        .then(res=>{
            callback();
        })
        .catch(err=>console.log(err));
    }

    const approveDriver = () => {
        authAxios.post(updateDriverStatus, {id: row.id})
        .then(res=>{
            callback();
        })
        .catch(err=>console.log(err));
    }
  
    return (
          <Card sx={{ position: 'absolute', top: '20%', left: '33%', boxShadow: 15,  zIndex: 'modal' }} raised={true}>
              <CardContent>
                    <div class="border-b-2 mb-2 pb-2">
                        <div class="flex items-end justify-between">
                        <Typography sx={{ color: '#1C085D', fontSize: 16, fontWeight: 'medium' }}>
                            Driver License
                        </Typography>
                        <IconButton
                      aria-label="close"
                      onClick={handleClose}
                      sx={(theme) => ({
                        color: theme.palette.grey[500],
                      })}
                    >
                    <CloseIcon />
                    </IconButton>
                        </div>
                        <Zoom>
                            <img
                            alt="driver license"
                            src={row.driver_license}
                            width="200"
                            />
                        </Zoom>
                    </div>
                    {row.vehicles.length > 0 && row.vehicles.map((v, index) => {
                        return (
                        <div class="border-b-2 mb-2">
                            <Typography sx={{ color: '#1C085D', fontSize: 16, fontWeight: 'medium' }}>
                                Car {index+1}
                            </Typography>
                            <div class="flex flex-col lg:flex-row items-start lg:items-end justify-start">
                                <div class="border-b-2 pb-2 lg:border-b-0 lg:border-r-2  lg:pr-2 lg:mr-2"> 
                                    <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>
                                        Registration
                                    </Typography>
                                    <Zoom>
                                        <img
                                        alt="registration"
                                        src={v.registration}
                                        width="200"
                                        />
                                    </Zoom>
                                </div>
                                <div class="border-b-2 pb-2 lg:border-b-0 lg:border-r-2  lg:pr-2 lg:mr-2"> 
                                    <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>
                                        Insurance
                                    </Typography>
                                    <Zoom>
                                        <img
                                        alt="insurance"
                                        src={v.insurance}
                                        width="200"
                                        />
                                    </Zoom>
                                </div>
                                <div class="pb-2">
                                    <div class="flex justify-start border-b-2 mb-2 gap-2">  
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                        Brand: 
                                    </Typography>
                                    <Typography gutterBottom sx={{ color: '#fb923c', fontSize: 14 }}>
                                        {v.brand} 
                                    </Typography>                                   
                                    </div>
                                    <div class="flex justify-start border-b-2 mb-2 gap-2">  
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                        Model:
                                    </Typography>
                                    <Typography gutterBottom sx={{ color: '#fb923c', fontSize: 14 }}>
                                        {v.model}
                                    </Typography>  
                                    </div>
                                    <div class="flex justify-start border-b-2 mb-2 gap-2">  
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                        Year:
                                    </Typography>
                                    <Typography gutterBottom sx={{ color: '#fb923c', fontSize: 14 }}>
                                        {v.year}
                                    </Typography> 
                                    </div>
                                    <div class="flex justify-start border-b-2 mb-2 gap-2">  
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                        Plate#: 
                                    </Typography>
                                    <Typography gutterBottom sx={{ color: '#fb923c', fontSize: 14 }}>
                                        {v.plate_number}
                                    </Typography> 
                                    </div>
                                    <div class="flex justify-start gap-2">  
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                        Capacity: 
                                    </Typography>
                                    <Typography gutterBottom sx={{ color: '#fb923c', fontSize: 14 }}>
                                        {v.capacity}
                                    </Typography> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        )
                    })} 
              </CardContent>
             { !row.status.includes('Approved') &&  <div class="flex flex-col lg:flex-row gap-5 items-center mx-4 mb-4 justify-start">
                <button type="submit" class="default_btn w-[200px]"onClick={()=>approveDriver()}>Approve</button>  
                <button type="submit" class="default_btn w-[200px]" onClick={()=>setOpenInputDialog(true)}>Reject</button>  
              </div> }
              <Dialog
                open={openInputDialog}>
                <DialogContent>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    name="email"
                    label="Why injected?"
                    type="text"
                    variant="standard"
                    sx={{width: 400}}
                    onChange={(e)=>setRejectReason(e.target.value)}
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>setOpenInputDialog(false)} >Cancel</Button>
                <Button onClick={()=>rejectDriver()} >OK</Button>
                </DialogActions>
            </Dialog>
          </Card>
    );
}

export default DriverDialog
