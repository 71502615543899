import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import PackageDetail from './PackageDetail';
import DriverDialog from './DriverDialog';
import RouteDialog from './RouteDialog';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import authAxios from '../utils';
import {getPackageDataUrl, routePathUrl, deleteAccountUrl} from '../constants'; 

const TABLECELL_FONTSIZE =12;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount, order, orderBy, onRequestSort, headCells} =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
      <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        <TableCell align={'right'} padding={'normal'}>Index</TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'right'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sort ?
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
            :
                headCell.label
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function EnhancedTableToolbar(props) {
    const { columns, callback, numSelected, deleteCallback } = props;
    const [searchKeyword, setSearchKeyword] = React.useState("");
    const [searchBy, setSearchBy] = React.useState(columns[1].id);

    const onSearch = ()=> {
        callback(searchKeyword, searchBy);
    };
    return (
      <Toolbar
        sx={[
          {
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          },

            {backgroundColor: '#E2E8F0', fontSize: 14},
        ]}
        variant="dense"
      >
        <div class="flex justify-between items-center grow">
            <div class="flex gap-2 justify-end  items-center">
                <input class='default_input text-sm h-full' onChange={(e)=>setSearchKeyword(e.target.value)}/>
                <div>search by</div>
                <select class='default_select text-sm w-30 h-full' value={searchBy} onChange={e=>setSearchBy(e.target.value)}>
                    {columns.map((column, index)=>{
                        return (
                            column.id !== 'index' && <option key={index} value={column.id}>{column.label}</option>
                        )
                    })}
                </select>
                <button class='default_btn text-sm' onClick={()=>onSearch()}>Go</button>
            </div>
            {numSelected > 0 && (
                <div class="flex gap-2 justify-start  items-center">
                    <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                    >
                    {numSelected} selected
                    </Typography>
                    <Tooltip title="Delete">
                        <IconButton onClick={deleteCallback}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )}
        </div>

  
      </Toolbar>
    );
  }
  
  EnhancedTableToolbar.propTypes = {
    columns: PropTypes.array.isRequired,
    callback: PropTypes.func.isRequired,
    numSelected: PropTypes.number.isRequired,
  };
  

const RecordTable = ({table, rows, columns, refreshCallback, approvedDrivers}) => {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchKeyword, setSearchKeyword] = React.useState("");
    const [searchBy, setSearchBy] = React.useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const onDeleteRows = () => {
    selected.forEach((id, i) => {
        if (table === 'package') {
            authAxios
                .delete(getPackageDataUrl+id+"/")
                .then(res=>refreshCallback())
                .catch(err=>console.log(err));   
        }
        if (table === 'driver') {
            authAxios
                .post(deleteAccountUrl, {id: parseInt(id)})
                .then(res=>refreshCallback())
                .catch(err=>console.log(err));        
        }
        if (table === 'route') {
            authAxios
                .delete(routePathUrl+id+"/")
                .then(res=>refreshCallback())
                .catch(err=>console.log(err));   
        }
    });
    setSelected([]);
  };

  const onRowChecked = (event, id) => {
    event.stopPropagation();    // stop to call row-click function
    let ts = [...selected];
    if (ts.includes(id)) {
        let index = ts.indexOf(id);
        ts.splice(index, 1);
    } else {
        ts.push(id);
    }
    setSelected(ts);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const [filteredRows, setFilteredRows] = React.useState(rows);
  let visibleRows = React.useMemo(
    () => {
        if (searchKeyword === "") {
            setFilteredRows(rows);
            return [...rows]
                .sort(getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        } else {
            let r = [...rows]
            .filter(item => item[searchBy] && item[searchBy].toLowerCase().includes(searchKeyword.toLowerCase()))
            .sort(getComparator(order, orderBy));
            setFilteredRows(r);
            return r.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }},
    [order, orderBy, page, rowsPerPage, rows, searchBy, searchKeyword],
  );

  const searchCallback = (keyword, by) => {
    setSearchBy(by);
    setSearchKeyword(keyword);
  };

  const [open, setOpen] = React.useState(false);
  const [openDriverDialog, setOpenDriverDialog] = React.useState(false);
  const [openRouteDialog, setOpenRouteDialog] =  React.useState(false);
  const [rowData, setRowData] = React.useState();
  const onClickRow = (table, row) => {
    if (table === 'package') {
        setOpen(true);
        setRowData(row);
    }
    if (table === 'driver') {
        setOpenDriverDialog(true);
        setRowData(row);
    }
    if (table === 'route') {
        setOpenRouteDialog(true);
        setRowData(row);        
    }
  }

  const callback = () => {
    if (table === 'package') {
        setOpen(false);
    }
    if (table === 'driver') {
        setOpenDriverDialog(false);
        refreshCallback();
    }
    if (table === 'route') {
        setOpenRouteDialog(false);
        refreshCallback();
    }
  }
 
  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar columns={columns} callback={searchCallback} numSelected={selected.length} deleteCallback={onDeleteRows} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'small'}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={columns}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = selected.includes(row.id);
                return (
                  <TableRow
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                    sx={[{ cursor: 'pointer'},    
                        { "&:hover": {
                        backgroundColor: "#E2E8F0"
                      }}]}
                    onClick={()=>onClickRow(table, row)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onClick={event => onRowChecked(event, row.id)}
                      />
                    </TableCell>
                    <TableCell align="right"  sx={{fontSize: TABLECELL_FONTSIZE}}>{index+1+page * rowsPerPage}</TableCell>
                    {'email' in row && 
                    <TableCell align="right"  sx={{fontSize: TABLECELL_FONTSIZE}}>{row.email}</TableCell>}
                    {'first_name' in row && 
                    <TableCell align="right"  sx={{fontSize: TABLECELL_FONTSIZE}}>{row.first_name}</TableCell>}
                    {'last_name' in row && 
                    <TableCell align="right"  sx={{fontSize: TABLECELL_FONTSIZE}}>{row.last_name}</TableCell>}
                    {'phone_number' in row && 
                    <TableCell align="right"  sx={{fontSize: TABLECELL_FONTSIZE}}>{row.phone_number}</TableCell>}
                    {'created_at' in row &&
                    <TableCell align="right"  sx={{fontSize: TABLECELL_FONTSIZE}}>{row.created_at}</TableCell>}
                    {'tag' in row && 
                    <TableCell align="right"  sx={{fontSize: TABLECELL_FONTSIZE}}>{row.tag}</TableCell>}
                    {'verified' in row && 
                    <TableCell align="right" sx={{fontSize: TABLECELL_FONTSIZE}}>{row.verified?"Yes":"No"}</TableCell>}
                    {'status' in row && 
                    <TableCell align="right"  sx={{fontSize: TABLECELL_FONTSIZE,
                        ...('status' in row && (row.status.includes('failed') || row.status.includes('Rejected')) && {backgroundColor: '#FEE2E2'}), 
                        ...('status' in row && (row.status.includes('delivered') || row.status.includes('finished') || row.status.includes('Approved')) && {backgroundColor: '#DCFCE7'}),
                        ...('status' in row && (row.status.includes('assigned') || row.status.includes('notified') || row.status.includes('Init')) && {backgroundColor: '#FeF9c3'}),
                    }}>{row.status}</TableCell>}
                    {'driver' in row && 
                    <TableCell align="right"  sx={{fontSize: TABLECELL_FONTSIZE}}>{row.driver}</TableCell>}
                    {'profit' in row && 
                    <TableCell align="right"  sx={{fontSize: TABLECELL_FONTSIZE}}>${row.profit}</TableCell>}
                    {'addresses' in row && 
                    <TableCell align="right"  sx={{fontSize: TABLECELL_FONTSIZE}}>
                        <select className='w-10 bg-white text-right'>
                            <option class="text-sm font-black">{row.addresses.length}</option>
                            {row.addresses.map((addr,index)=>{
                        return (
                            <option key={index} class="text-xs">{addr.street+", "+addr.city+", "+addr.state+" "+addr.zipcode}</option>
                        )
                    })}</select></TableCell>}
                    {'customer' in row && 
                    <TableCell align="right" sx={{fontSize: TABLECELL_FONTSIZE}}>{row.customer}</TableCell>}
                    {'route' in row && 
                    <TableCell align="right" sx={{fontSize: TABLECELL_FONTSIZE}}>{row.route}</TableCell>}
                    {'address' in row && 
                    <TableCell align="right" sx={{fontSize: TABLECELL_FONTSIZE}}>{row.address}</TableCell>}
                    {'business' in row && 
                    <TableCell align="right" sx={{fontSize: TABLECELL_FONTSIZE}}>{row.business}</TableCell>}
                  </TableRow>
                );
              })}
              {open && <PackageDetail callback={callback} row={rowData} />}
              {openDriverDialog && <DriverDialog callback={callback} row={rowData} />}
              {openRouteDialog && <RouteDialog callback={callback} row={rowData} approvedDrivers={approvedDrivers.filter(driver => driver.first_name+" "+driver.last_name !== rowData.driver)} />}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

export default RecordTable;