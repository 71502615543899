import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { resendEmailUrl } from '../constants';
import axios from 'axios';

const VerifyEmail = () => {
    const { state } = useLocation();
    const navigate = useNavigate();

    const goHome = () => {
        navigate("/");
    };

    const resendEmail = () => {
        axios
        .post(resendEmailUrl, {email: state.email})
        .then(res => {
        })
        .catch(err => {
            console.log(err);
        }); 
    };

  return (
    <div class="flex flex-col gap-2 mt-4 lg:mt-8 items-center justify-center">
        <div  class="flex w-[600px] flex-col gap-2 items-center">
                <h3 class="text-xl font-medium leading-6 text-gray-900">A confirmation email has been sent to {state.email}</h3>
                <p class="default_text">Please click on the link in the email to complete your registration.</p>   
                <button class="default_btn w-[200px] mt-2" onClick={()=>goHome()} >Return to Login</button>
                <h3 class="text-xl font-medium leading-6 text-gray-900">Is the link expired?</h3>
                <button class="default_btn w-[200px] mt-2" onClick={()=>resendEmail()}>Resend Email</button>
        </div>
    </div>
  )
}

export default VerifyEmail