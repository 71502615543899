import React from 'react'
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
    Image,
} from "@react-pdf/renderer";
  // Create styles
const styles = StyleSheet.create({
    page: {
      backgroundColor: "#fff",
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 50,
      paddingRight: 50,
      lineHeight: 1.5,
      flexDirection: "column"
    },
    logo: {
      width: 48,
      height: 48
    },
    container: {
      display: "flex",
      flexDirection: "row",
      flexWrap: 'wrap',
      gap: 4,
      alignItem: "center",
      justifyContent: "space-evenly"
    },
    tag: {
        display: "flex",
        flexDirection: "row",
        marginBottom: 8,
        padding: 4,
        border: 2,
        borderColor: "#000",
        flexGrow: 0,
        flexWrap: 1,
        width: 240,  
        justifyContent: 'space-between',      
    },
    text: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        alignItems: "start",
    },
    title: {
        fontSize: 14,
        fontStyle: "bold"
    },
    info: {
        fontSize: 8,
    },
  });

  const TagComponent = ({ tags }) => {
    return (
        <View style={styles.container} >
            {tags && tags.length > 0 && tags.map((tag, index) =>
                <View style={styles.tag} key={index} wrap={false}>
                    <View style={styles.text}> 
                    <Text style={styles.title}>{tag.tag}</Text>
                    <Text style={styles.info}>{tag.customer}</Text>
                    <Text style={styles.info}>{tag.address}</Text>
                    </View>
                    <Image
                        style={styles.logo}
                        src={tag.qrcode}
                        />
                </View>
            )}
        </View>
    );
  };
const TagPdf = ({packages}) => {
    return (
        <PDFViewer style={{ width: '100%', height: '500px' }}>
          {/* Start of the document*/}
          <Document>
            {/*render a single page*/}
            <Page size={"Letter"}  style={styles.page}>
                <TagComponent tags={packages}/>
            </Page>
          </Document>
        </PDFViewer>
      );
}

export default TagPdf