import React, { useState } from 'react'
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import authAxios from '../utils';
import { notifyDriverRouteAssignURL } from '../constants';

const RouteDialog = ({callback, row, approvedDrivers}) => {
    const [driver, setDriver] = useState('');
    const handleChange = (event) => {
      setDriver(event.target.value);
    };

    const onClickCancel = () => {
        callback();
    };

    const onClickOk = () => {
        if (driver !== '') { 
            authAxios.post(notifyDriverRouteAssignURL, {driver_assigned_routes: [{id: parseInt(row.id), driver_id: parseInt(driver)}]})
                .then((response)=> {
                    callback();
                })
                 .catch(err=>console.log(err));
        } 
    };

    return (
        <Card sx={{position: 'absolute', top: '10%', right: '10%', boxShadow: 15,  zIndex: 'modal' }} raised={true}>
            <CardContent>
            <div class="border-b-2 mb-2 pb-2">
            <Typography sx={{ color: '#1C085D', fontSize: 16, fontWeight: 'medium', textAlign: 'center' }}>
                {row.id}
            </Typography>
            </div>
            <div class="flex flex-col justify-center gap-2 items-start border-b-2 pb-2 mb-2">
                {row.addresses.map((addr, index)=>
                    {
                        let packages = addr.packages.filter(p=>p.route === row.id);
                        return (<Typography gutterBottom sx={{ color: 'text.secondary',backgroundColor: `${(packages.length > 0 ? (packages[0].status === 'PD' ? '#DCFCE7' : (packages[0].status === 'DF' ? "#FEE2E2" : "#FeF9c3")) : "#FeF9c3" )}`, fontSize: 14 }}>
                            {addr.street+", "+addr.city+", "+addr.state+" "+addr.zipcode+" ("+packages.length+")"}
                        </Typography>)
                    }
                )}            
            </div>
            {row.status === 'driver assigned' ? <>
            <div class="flex justify-center items-center gap-2 border-b-2 pb-2 mb-2">
                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                    Reassign current route to :
                </Typography>
                <Select
                    onChange={handleChange}
                    sx={{ m: 1, minWidth: 120 }} 
                    size="small"
                    defaultValue = ""
                >
                    <MenuItem value="">
                    <em>None</em>
                    </MenuItem>
                    {approvedDrivers.map((driver, index) => (
                         <MenuItem key={index} value={driver.id}>{driver.first_name+" "+driver.last_name}</MenuItem>
                    ))}
                </Select>
            </div>
            <div class="flex justify-center gap-2 items-center pt-2">
                <Button variant="outlined" onClick={()=>onClickCancel()} sx={{width: 150}}>Close</Button>
                <Button variant="outlined" onClick={()=>onClickOk()} sx={{width: 150}}>Assign</Button>
            </div></>
            : 
            <div class="flex justify-center gap-2 items-center pt-2">
                <Button variant="outlined" onClick={()=>onClickCancel()} sx={{width: 150}}>Close</Button>
            </div>}

            </CardContent>
    </Card>
    );  
}

export default RouteDialog