import * as React from 'react';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';

const PackageDetail = ({callback, row})=> {
  const handleClose = () => {
    callback();
  };

  return (
        <Card sx={{ position: 'absolute', top: '10%', right: '10%', boxShadow: 15, zIndex: 'modal'  }} raised={true}>
            <CardHeader
                avatar={
                    <Avatar
                    alt="Package QRCode"
                    src={row.qrcode}
                    sx={{ width: 64, height: 64 }}
                    variant='square'
                    />
                }
                action={
                    <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                      color: theme.palette.grey[500],
                    })}
                  >
                  <CloseIcon />
                  </IconButton>
                }
                title={row.tag}
                titleTypographyProps={{variant:'h6' }}
                //sx={{ color: 'green'}}
            />
            <CardContent>
            <div class="flex gap-4 justify-between border-b-2 mb-2">
            <Typography sx={{ fontSize: 16, fontWeight: 'medium' }}>
                Route: {row.route}
            </Typography>
            <Typography sx={{ color: '#1C085D', fontSize: 16, fontWeight: 'medium' }}>
                {row.address}
            </Typography>
            </div>
            <div class="flex gap-4 justify-between border-b-2 mb-2">
            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                Accepted at: {row.created_at}
            </Typography>
            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                Delivered at: {row.status !== 'route assigned' ? row.updated_at : '___'}
            </Typography>
            </div>
            <div class="flex gap-4 justify-between border-b-2 mb-2">
            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                Customer Name: {row.customer}
            </Typography>
            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                Customer Phone: {row.customer_phone}
            </Typography>
            </div>
            <div class="flex gap-4 justify-between border-b-2 mb-2">
            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                Delivered: {row.status}
            </Typography>
            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                Store: {row.business}
            </Typography>
            </div>
            {row.status.includes("failed") ? 
            (<div class="flex justify-start text-wrap">
                <Typography gutterBottom sx={{ color: '#EB001B', fontSize: 16, fontWeight: 'medium' }}>
                    {row.memo}
                </Typography>
            </div>
            ) : (
                row.status.includes("delivered") ?
                (      <CardMedia
                    component="img"
                    image={row.photo}
                    alt="Delivered Proof"
                  />):(<></>)
            ) }
            </CardContent>
        </Card>
  );
}

export default PackageDetail;