//export const localhost = "http://127.0.0.1:8000";
export const localhost = "https://www.auspdelivery.com";

const apiURL = "/api";

export const endpoint = `${localhost}${apiURL}`;

export const refreshTokenUrl = `${localhost}/token/refresh/`;
export const unroutedAddressesUrl = `${endpoint}/route/unrouted-addresses/`;
export const moveAddressBetweenRoutesUrl = `${endpoint}/route/move-address/`;
export const getAddrCoordinateUrl = addr => `${endpoint}/route/get-addr-coordinate/?address=${addr}`;
export const clusterAddressesUrl = size => `${endpoint}/route/cluster-addresses/?size=${size}`;
export const getActiveRoutesUrl = `${endpoint}/route/get-active-routes`;
export const updateRouteAddrOrderURL = `${endpoint}/route/update-route-addr-order/`;
export const updateRouteURL = `${endpoint}/route/update-route/`;
export const notifyDriverRouteAssignURL = `${endpoint}/route/notify-driver-route-assign/`;

export const updateRouteStatus = `${endpoint}/route/update-route-status/`;
export const routePathUrl = `${endpoint}/route/`;
export const getPackageDataUrl = `${endpoint}/package/`;

export const getDriverListUrl = `${endpoint}/user/drivers/`;
export const updateDriverStatus = `${endpoint}/user/driver/status/`;

export const getUserInfoUrl = `${endpoint}/user/`;
export const userSignupUrl = `${endpoint}/user/register/`;
export const userLoginUrl = `${endpoint}/user/login/`;
export const deleteAccountUrl = `${endpoint}/user/delete/`;

export const passwordResetUrl = `${localhost}/password/reset/`;
export const pwdResetConfirmUrl = `${localhost}/password/reset/confirm/`;
export const changePasswordUrl = `${localhost}/password/change/`;

export const resendEmailUrl = `${localhost}/resend-email/`;


export const user_group = { Guest: "guest", Driver: "driver", Business: "business", Broker: "broker"};